import React, { useRef, useEffect } from "react";
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import GatsbyLink from "../GatsbyLink";

const ImageContainer = styled.div`
  margin-bottom: 2rem;
  .block-img {
    max-width: 700px;
    margin: auto;
    margin-bottom: 2rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  .block-img-ipad {
    max-width: 300px;
    margin: auto;
    margin-bottom: 2rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  .image-label {
    font-style: italic;
    font-weight: 400;
    color: rgba(17, 23, 29, 0.6);
    text-align: center;
    a {
      text-decoration: none;
      color: #000;
      :hover {
        text-decoration: underline;
      }
    }
  }
  .gifImage {
    max-width: 450px;
  }
  .emphasized {
    max-width: 700px;
    margin: auto;
  }
  .fullImage {
    height: 400px;
    position: relative;
    font-family: serif;
    font-weight: 400;
    background-color: white;
    background-size: cover;
    color: white;
    margin-bottom: 3rem;

    @media (max-width: 600) {
      padding: 20px;
      height: 200px;
    }

    .image-label {
      font-size: 40px;
      font-weight: 900;
      color: #ffffff;

      @media (max-width: 960) {
        font-size: 25px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .wrapperFullText {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    @media (max-width: $max-width-sm) {
      width: 80%;
    }
  }
`;
// Default Image
const DefaultImage = ({ slice }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    const imgObserver = new IntersectionObserver(
      (entries, imgObserver) => {
        entries.forEach((element) => {
          if (!element.isIntersecting) {
            return;
          } else {
            const src = element.target.getAttribute("data-src");
            element.target.src = src;
            imgObserver.unobserve(element.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (imageRef.current) imgObserver.observe(imageRef.current);
    return () => {
      imgObserver.disconnect();
    };
  }, []);

  return (
    <ImageContainer>
      <figcaption className="block-img ">
        <img
          loading="lazy"
          ref={imageRef}
          data-src={slice.primary.image_banner.url}
          alt={slice.primary.image_banner.alt}
        />
        {slice.primary.description &&
        RichText.asText(slice.primary.description.raw) !== "" ? (
          <figcaption className="image-label">
            <RichText
              render={slice.primary.description.raw || []}
              serializeHyperlink={GatsbyLink}
            />
          </figcaption>
        ) : null}
      </figcaption>
    </ImageContainer>
  );
};

const GifImage = ({ slice }) => {
  const imageRef = useRef(null);
  useEffect(() => {
    const imgObserver = new IntersectionObserver(
      (entries, imgObserver) => {
        entries.forEach((element) => {
          if (!element.isIntersecting) {
            return;
          } else {
            const src = element.target.getAttribute("data-src");
            element.target.src = src;
            imgObserver.unobserve(element.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (imageRef.current) imgObserver.observe(imageRef.current);
    return () => {
      imgObserver.disconnect();
    };
  }, []);

  return (
    <ImageContainer>
      <figcaption className="block-img gifImage">
        <img
          loading="lazy"
          ref={imageRef}
          data-src={slice.primary.image_banner.url}
          alt={slice.primary.image_banner.alt}
        />
        {slice.primary.description &&
        RichText.asText(slice.primary.description.raw) !== "" ? (
          <figcaption className="image-label">
            {RichText.asText(slice.primary.description.raw)}
          </figcaption>
        ) : null}
      </figcaption>
    </ImageContainer>
  );
};

// Emphasized Image
const EmphasizedImage = ({ slice }) => {
  const imageRef = useRef(null);
  useEffect(() => {
    const imgObserver = new IntersectionObserver(
      (entries, imgObserver) => {
        entries.forEach((element) => {
          if (!element.isIntersecting) {
            return;
          } else {
            const src = element.target.getAttribute("data-src");
            element.target.src = src;
            imgObserver.unobserve(element.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (imageRef.current) imgObserver.observe(imageRef.current);
    return () => {
      imgObserver.disconnect();
    };
  }, []);
  return (
    <ImageContainer>
      <figcaption className="block-img emphasized">
        <img
          loading="lazy"
          ref={imageRef}
          data-src={slice.primary.image_banner.url}
          alt={slice.primary.image_banner.alt}
        />
        {slice.primary.description &&
        RichText.asText(slice.primary.description.raw) !== "" ? (
          <figcaption className="image-label">
            {RichText.asText(slice.primary.description.raw)}
          </figcaption>
        ) : null}
      </figcaption>
    </ImageContainer>
  );
};

const EmphasizedImageIpad = ({ slice }) => {
  const imageRef = useRef(null);
  useEffect(() => {
    const imgObserver = new IntersectionObserver(
      (entries, imgObserver) => {
        entries.forEach((element) => {
          if (!element.isIntersecting) {
            return;
          } else {
            const src = element.target.getAttribute("data-src");
            element.target.src = src;
            imgObserver.unobserve(element.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (imageRef.current) imgObserver.observe(imageRef.current);
    return () => {
      imgObserver.disconnect();
    };
  }, []);
  return (
    <ImageContainer>
      <figcaption className="block-img-ipad ">
        <img
          loading="lazy"
          ref={imageRef}
          data-src={slice.primary.image_banner.url}
          alt={slice.primary.image_banner.alt}
        />
        {slice.primary.description &&
        RichText.asText(slice.primary.description.raw) !== "" ? (
          <figcaption className="image-label">
            {RichText.asText(slice.primary.description.raw)}
          </figcaption>
        ) : null}
      </figcaption>
    </ImageContainer>
  );
};

// Full Width Image
const FullWidthImage = ({ slice }) => (
  <ImageContainer
    className="fullImage"
    style={{
      backgroundImage: `url(${slice.primary.image_banner.url})`,
      height: 400,
      position: "relative",
      fontWeight: 400,
      backgroundColor: "white",
      backgroundSize: "cover",
      color: "white",
      marginBottom: "3rem",
    }}
  >
    <div className="wrapperFullText">
      {slice.primary.description &&
      RichText.asText(slice.primary.description.raw) !== "" ? (
        <span
          style={{
            fontSize: 40,
            fontWeight: 900,
            color: "#FFFFFF",
          }}
        >
          {RichText.asText(slice.primary.description.raw)}
        </span>
      ) : null}
    </div>
  </ImageContainer>
);

// Function to determine the image type
const renderSwitch = (slice) => {
  switch (slice.slice_label) {
    case "full":
      return <FullWidthImage slice={slice} />;
    case "emphasized":
      return <EmphasizedImage slice={slice} />;
    case "customgif":
      return <GifImage slice={slice} />;
    case "ipad":
      return <EmphasizedImageIpad slice={slice} />;
    default:
      return <DefaultImage slice={slice} />;
  }
};

const ImageCaption = ({ slice }) => <>{renderSwitch(slice)}</>;

export default ImageCaption;
